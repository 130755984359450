import React from "react"
import Layout from "./layout"
import { graphql } from "gatsby"
import ntsc from "../images/ntsc_color_bars.jpeg"
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
// import InnerHTML from 'dangerously-set-html-content'
export default function TVGrid({data}) {
  const vids = data.allFile.edges.map(edge => edge.node.publicURL)
  const post = data.markdownRemark
  const img = post.frontmatter.background_images[0];
  const transforms = post.frontmatter.tvgrid_transforms;
  const color_bars = post.frontmatter.ntsc;
  const setWindowSize = () => {
    if(typeof window === "undefined")
      return {
          perspective: post.frontmatter.tvgrid_perspective+"px"
      };
    return {
      perspective: post.frontmatter.tvgrid_perspective+"px"
    }
  }
  const image = getImage(img)
  const tvSetsPage = post.frontmatter.title === "TV-sets"
  return (
    <Layout data={data}>
      <div className="tvgrid">
        <GatsbyImage image={image} alt={""} />
        <div className="tv" style={setWindowSize()}>
          {post.frontmatter.tvgrid_positions.map((pt,i) =>
            <video playsInline loop autoPlay muted key={i} src={vids[(post.fields.slug.length+i)%vids.length]} style={{
                width: post.frontmatter.tvgrid_widths[i]+"%",
                left: pt[0]+"%",
                top: pt[1]+"%",
                transform: transforms.length > i ? `${tvSetsPage ? `translate3d(0,0,${i*-30+50}px)` : ""} rotateX(${transforms[i][0]}deg) rotateY(${transforms[i][1]}deg) rotateZ(${transforms[i][2]}deg)` : "",
                zIndex: post.frontmatter.tvgrid_positions.length -i
              }}></video>
          )}
        </div>
      </div>
      <div className="tvgrid ntsc" style={{
            backgroundImage: "url("+ ntsc +")",
            display: color_bars ? "block" : "none"
          }}></div>
    </Layout>
  )
}
