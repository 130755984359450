import TvGrid from "../components/tvgrid"
import { graphql } from "gatsby"

export default TvGrid

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        tags
        tvgrid_positions
        tvgrid_widths
        tvgrid_transforms
        tvgrid_perspective
        ntsc
        background_images {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 1500
            )
          }
        }
      }
      fields {
        slug
        table
      }
    }

      allFile(filter: {extension: {eq: "mp4"}, relativeDirectory: {glob: "tables/fraud/vidpages/*"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
  }
`
